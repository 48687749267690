<template>
    <div>
      <div class='main-loader' v-if='isLoading'></div>
      <div class="product-page">
        <b-container>
          <search-bar :showSwiper="false" @search-input="generateImages" />
          <div v-if="prevImages.length">
            <b-row>
              <product-card 
                v-for="(product, index) in prevImages" 
                :key="`${product.id}-${index}`" 
                :product="product" 
                @toggle-like="updateLikeStatus" 
                :showShareButton="false"
                :showChatButton="true"
                :src="product.image_url" 
              />
            </b-row>
          </div>
          <div class="no-data mt-5 mb-5" v-else-if="initialLoadingComplete && !prevImages.length && !isLoading">
            <div class="mx-auto text-center">
              <h2>{{ noDataMessage }}</h2>
            </div>
          </div>
        </b-container>
      </div>
    </div>
  </template>
  
  
    
  <script>
  import SearchBar from './searchBar.vue';
  import ImageGallery from './ImageGallery.vue';
  import productCard from './imageCard.vue';
  import { mapState, mapActions, mapGetters } from 'vuex';
  import { EventBus } from '@/event-bus'; 
  import { saveImagesToIndexedDB, getImagesFromIndexedDB } from '@/utils/indexedDB';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
  import axios from 'axios'
  export default {
    components: {
      SearchBar,
      ImageGallery,
      productCard,
    },
    data() {
      return {
        isLoading: false,
        prevImages: [],
        noDataMessage: 'Enter prompt to start generating images...',
        initialLoadingComplete: false,
        guestRequestCount: parseInt(localStorage.getItem('guestRequestCount')) || 0, // Initialize from localStorage
        currentPage: '', 
      };
    },
    created() {
      this.$store.dispatch('app/syncUserFromStorage');
      EventBus.$on('generate-images', this.generateImages);
    },
    computed: {
      ...mapState('app', {
        Banner: state => state.banner,
        // FeaturedImages: state => state.featured_images,
        ...mapState('images', ['images']),
      }),
      ...mapGetters('app', ['isLogged', 'userData']),
      userId() {
        return this.userData?.userId || null;
      },
    },
    watch: {
      isLogged(newValue) {
        if (newValue) {
          // Reset guest request count when user logs in
          this.guestRequestCount = 0;
          localStorage.removeItem('guestRequestCount');
          this.fetchImagesByPage();
        }
      },
      $route(to, from) {
        this.fetchImagesByPage(); // Automatically fetch images when the route changes
      }
    },
    mounted() {
      window.addEventListener('scroll', this.onScroll);
      if (this.isLogged) {
        this.fetchImagesByPage(this.currentPage);
      } else {
        this.initialLoadingComplete = true;
      }
      this.currentPage = this.$route.name;
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.onScroll); // Clean up the event listener
    },
  
    methods: {
      ...mapActions('app', [
        // 'fetchFeaturedItems',
        'loadFeaturedImagesFromStorage',
        'syncUserFromStorage',
      ]),
  
        // Detect when the user scrolls to the bottom of the page and fetch more images
    onScroll() {
      const bottomOfWindow = window.innerHeight + window.scrollY >= document.documentElement.offsetHeight - 100;
      if (bottomOfWindow && !this.isLoading) {
        this.fetchMoreImages(); // Fetch the next set of images when user scrolls down
      }
    },
  
  
      async generateImages(prompt, realistic, imageType) {
        const baseEndpoint = 'https://api.briks.ai';
        let endpoint;
        let agentId;
    if (imageType === 'images') {
      endpoint = `${baseEndpoint}/web/images`;
      agentId = 'agent-vuTgMx5ib8nQbH3zlQaKk';
    } else {
      throw new Error('Invalid image type');
    }
        const topic = prompt;
        const imageStyle = 'realistic'; 
        const params = new URLSearchParams({ 
          prompt: prompt,
          imageStyle: imageStyle,
          topic: topic,
        }); 
        const url = `${endpoint}?${params.toString()}`;
  
        const userId = this.userData?.userId;
        const tenant = JSON.parse(localStorage.getItem('domain_Data'));
        const tenantId = tenant?.tenant_id;
  
        // Allow up to 3 requests for non-logged-in users
        if (!userId) {
          if (this.guestRequestCount < 3) {
            this.guestRequestCount += 1;
            localStorage.setItem('guestRequestCount', this.guestRequestCount);
  
            // Optionally inform the user
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `You have ${3 - this.guestRequestCount} free request(s) left. Please login to continue and save your creations in your account.`,
                icon: 'InfoIcon',
                variant: 'info',
              },
            });
          } else {
            this.$bvModal.show("modal-login"); 
            return;
          }
        }
  
        this.isLoading = true; // Start loading
        EventBus.$emit('update-generating', true); // Update generating status
  
        try {
          const response = await fetch(url, {
            headers: { 
              'Accept': 'text/event-stream',
            },
            redirect: 'follow',
          });
  
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
  
          const reader = response.body.getReader();
          const decoder = new TextDecoder();
          let imageUrls = [];
  
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            
            const chunk = decoder.decode(value);
            const events = chunk.split('\n\n').filter(Boolean);
  
            for (const event of events) {
              try {
                const data = JSON.parse(event.replace(/^data: /, ''));
                if (data.success) {
                  let items = [];
                  if (imageType === 'memes') {
                    items = data.meme ? [data.meme] : data.memes;
                  } else {
                    items = data.image ? [data.image] : data.images;
                  }
  
                  imageUrls = items.map(item => {
                    const id = item.split('/').pop().split('.').shift(); // Extract ID and remove extension
                    const imageUrl = `${item}`;
                    return {
                      id: id,
                      image_url: imageUrl,
                      prompt: prompt,
                      like_or_dislike: 'N',
                    };
                  });
  
                  // Check for duplicates before adding
                  for (const newImage of imageUrls) {
                    if (!this.prevImages.some(image => image.id === newImage.id)) {
                      this.prevImages.unshift(newImage); // Add new images at the top
  
                      // Save the new image to Cosmos DB if user is logged in
                      if (userId) {
                        await this.saveImageToDB(
                          newImage.id,
                          userId,
                          tenantId,
                          newImage.image_url,
                          prompt,
                          imageStyle,
                          topic,
                          imageType,
                        );
                      }
                    }
                  }
                  if (data.message === "completed") {
                    this.prevImages = [...this.prevImages];
                    await saveImagesToIndexedDB(this.prevImages);
                    this.isLoading = false;
                    EventBus.$emit('update-generating', false); // Update generating status
                    await this.updateExecutionCount(agentId, userId, { prompt, imageStyle, imageType });
                  }
                }
              } catch (error) {
                console.error('Error parsing SSE data:', error);
              }
            }
          }
  
          this.isLoading = false; // End loading
  
        } catch (error) {
          console.error('Error in generateImages:', error);
          this.isLoading = false;
          EventBus.$emit('update-generating', false);
        }
      },
  
      async saveImageToDB(id, userId, tenantId, imageUrl, prompt, imageStyle, topic, imageType) {
    try {
      const creation = 'user';
  
      const response = await fetch(`${process.env.VUE_APP_BASE_URL}/saveImage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          userId,
          tenantId,
          imageUrl,
          prompt,
          imageStyle,
          topic,
          creation, 
          imageType 
        }),
      });
  
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error('Error saving image to DB:', error);
    }
  },
  
  
  async fetchImagesByPage() {
      let imageType;
      const currentPath = this.$route.path;
  
      // Determine the imageType based on the current route path
      if (currentPath.includes('/memes')) {
        imageType = 'memes';
      } else if (currentPath.includes('/images')) {
        imageType = 'images';
      } else if (currentPath.includes('/quotes')) {
        imageType = 'quotes';
      } else {
        throw new Error(`Invalid path: ${currentPath}`);
      }
  // Fetch images based on the imageType
      await this.fetchImages(imageType);
    },
  
    async fetchMoreImages() {
    // Prevent multiple requests if there is no continuationToken or loading is already in progress
    if (!this.continuationToken || this.isLoading) return;
    
    // Pass the current imageType and fetch the next batch
    await this.fetchImages(this.imageType, 20, this.continuationToken); // Fetch next 20 images
  },
  
     // Fetch images with pagination support
  async fetchImages(imageType = 'all', pageSize = 20, continuationToken = null) {
    this.isLoading = true;
    this.imageType = imageType; // Store the current imageType for further fetches
  
    try {
      // First, try to get images from IndexedDB based on imageType
      const cachedImages = await getImagesFromIndexedDB();
      if (cachedImages.length > 0 && !continuationToken) {
        this.prevImages = cachedImages.filter(image => image.imageType === imageType || imageType === 'all');
        this.initialLoadingComplete = true;
      }
  
      // If the user is logged in, fetch images from the API
      const userId = this.userData?.userId;
      if (userId) {
        const url = new URL(`${process.env.VUE_APP_BASE_URL}getImages`);
        url.searchParams.append('userId', userId);
  
        // Always include the imageType in the request
        if (imageType !== 'all') {
          url.searchParams.append('imageType', imageType);
        }
        
        url.searchParams.append('pageSize', pageSize);
        if (continuationToken) {
          url.searchParams.append('continuationToken', continuationToken);
        }
  
        const response = await fetch(url.toString());
        const data = await response.json();
  
        if (Array.isArray(data.images)) {
          // If no more images are returned, stop further loading
          if (data.images.length === 0) {
            this.continuationToken = null;
            return;
          }
  
          // Append new images to the existing ones
          this.prevImages.push(...data.images);
          this.continuationToken = data.continuationToken; // Store the new continuation token
          this.initialLoadingComplete = true;
  
          // Save the new images to IndexedDB
          await saveImagesToIndexedDB(data.images);
        }
      }
    } catch (error) {
      console.error('Error fetching images:', error);
    } finally {
      this.isLoading = false;
    }
  },
    
     // Function to update execution count after image generation
    async updateExecutionCount(agentId, userId, executionDetails) {
    try {
      const baseUrl = process.env.VUE_APP_BASE_URL || 'https://api.briks.ai/'; 
      const endpoint = `${baseUrl}agents/runupdate/${agentId}`;
  
      // Send the PATCH request to update the execution count
      const response = await axios.patch(endpoint, {
        userId: userId,    // Pass the userId for tracking
        incrementBy: 1,
        executionDetails: {
          prompt: executionDetails.prompt,
          imageStyle: executionDetails.imageStyle,
          imageType: executionDetails.imageType,
        },
      });
  
      if (response.status !== 200) {
        throw new Error('Failed to update execution count');
      }
  
      console.log(`Execution count updated for agent: ${agentId}`);
    } catch (error) {
      console.error('Error updating execution count:', error);
    }
  },
  
  
      async updateLikeStatus(imageId, likeStatus) {
        const userId = this.userData?.userId;
  
        if (!userId) {
          this.$bvModal.show("modal-login");
          return;
        } 
        try {
          const response = await fetch(`${process.env.VUE_APP_BASE_URL}updateLikeStatus`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId, imageId, likeStatus }),
          });
  
          const data = await response.json();
  
          if (data.message === 'Like status updated successfully!') {
            // Update the local state to reflect the new like status
            const image = this.prevImages.find(img => img.id === imageId);
            if (image) {
              image.like_or_dislike = likeStatus;
            }
          }
        } catch (error) {
          console.error('Error updating like status:', error);
        }
      },
  
    },
    
  };
  </script>
  
    
    <style scoped>
    .search-input {
      border-radius: 0rem !important;
    }
    </style>
    <style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    
  
    .swiper-slide {
      display: flex;
      align-items: 'auto';
      justify-content: center;
      padding: 2px !important;
      padding-bottom: 15px !important;
     
      box-sizing: border-box; /* Includes padding and border in the element's total width and height */
  }
  
  
  .swiper-container {
      width: 100%;            /* Ensures the swiper takes full width */
      overflow-x: auto;       /* Enables horizontal scrolling for the container */
      -webkit-overflow-scrolling: touch; /* Smooth scrolling on touch devices */
      scrollbar-width: none;  /* Hides scrollbar in Firefox */
      -ms-overflow-style: none;  /* Hides scrollbar in IE and Edge */
  }
  
  .swiper-container::-webkit-scrollbar {
      display: none;  /* Hides scrollbar in WebKit browsers */
  }
  
    </style>
    